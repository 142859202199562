export const extractConnectionParameter = (str: string): string => {
    // Split the string by both '/' and '@' and get the last element
    const parts: string[] = str.split(/[/@]/);
    const lastPart: string = parts[parts.length - 1];
    // Add a space before each capital letter (excluding the first character) and trim the result
    const formatted: string = lastPart.replace(/([A-Z])/g, ' $1').trim();
    // Capitalize the first letter of each word
    const capitalizedResult: string = formatted.replace(/\b\w/g, (char) => char.toUpperCase());
    return capitalizedResult;
};
