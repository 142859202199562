export const SCHEDULE_INTERVAL_OPTIONS = [
    {value: 'Minutes', description: 'scheduleBlock.description.minutes'},
    {value: 'Hour', description: 'scheduleBlock.description.hour'},
    {value: 'Day',  description: 'scheduleBlock.description.day'},
]

export const DEFAULT_HOURS = [
    {value: 'scheduleBlock.hour8'},
    {value: 'scheduleBlock.hour18'},
]

export const SCHEDULE_HOURS_OPTIONS = [
    {value: 'scheduleBlock.hour0'},
    {value: 'scheduleBlock.hour1'},
    {value: 'scheduleBlock.hour2'},
    {value: 'scheduleBlock.hour3'},
    {value: 'scheduleBlock.hour4'},
    {value: 'scheduleBlock.hour5'},
    {value: 'scheduleBlock.hour6'},
    {value: 'scheduleBlock.hour7'},
    {value: 'scheduleBlock.hour8'},
    {value: 'scheduleBlock.hour9'},
    {value: 'scheduleBlock.hour10'},
    {value: 'scheduleBlock.hour11'},
    {value: 'scheduleBlock.hour12'},
    {value: 'scheduleBlock.hour13'},
    {value: 'scheduleBlock.hour14'},
    {value: 'scheduleBlock.hour15'},
    {value: 'scheduleBlock.hour16'},
    {value: 'scheduleBlock.hour17'},
    {value: 'scheduleBlock.hour18'},
    {value: 'scheduleBlock.hour19'},
    {value: 'scheduleBlock.hour20'},
    {value: 'scheduleBlock.hour21'},
    {value: 'scheduleBlock.hour22'},
    {value: 'scheduleBlock.hour23'},
]

export const DAYS_DATA = [
    {value: 'daysBlock.sunday'},
    {value: 'daysBlock.monday'},
    {value: 'daysBlock.tuesday'},
    {value: 'daysBlock.wednesday'},
    {value: 'daysBlock.thursday'},
    {value: 'daysBlock.friday'},
    {value: 'daysBlock.saturday'},
]