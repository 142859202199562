import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useDefaultInputField from 'hooks/useDefaultInputField';
import { IDefaultInputField, IFormField } from 'interfaces/IForm';

import styles from './DefaultInputField.module.scss';
import { ExInput } from '@boomi/exosphere';
import './DefaultInputField.scss';

export const DefaultInputField: FC<IDefaultInputField> = ({ setValue, register, errors, defaultValue, field = {} as IFormField, validationOptions }) => {
    const { inputName, disabled, label, required, description, type } = field;
    const { inputType } = useDefaultInputField(type || 'text', field.hiddenValue);
    const { t } = useTranslation();
    const { fieldDescription, fieldBlock, fieldBlock_inputGroup, fieldErrorBlock } = styles;
    const errorField = errors[inputName];
    const errorMessage = errorField ? String(errorField.message || 'This field is required') : '';

    useEffect(() => {
        if (defaultValue != null) {
            setValue(inputName, defaultValue);
        }
        if (field.hiddenValue && label !== 'Client Secret') {
            setValue(inputName, '[Hidden]');
        }
    }, [defaultValue]);

    return (
        <div className={`${errorField && fieldErrorBlock} ${fieldBlock}`}>
            <p className={fieldDescription}>{t(description || '')}</p>
            <label>{t(label)}</label>
            <div className={fieldBlock_inputGroup}>
                <ExInput
                    required={required}
                    {...register(inputName, validationOptions)}
                    disabled={disabled}
                    type={inputType}
                    togglePassword={true}
                    className="default-input"
                    invalid={!!errorMessage}
                    errorMsg={errorMessage}
                    placeholder={`Enter ${label}`}
                />
            </div>
        </div>
    );
};

export default DefaultInputField;
