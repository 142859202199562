import { IConfigureField, IConnection, IEnvironmentExtensions } from 'interfaces/IConfigureConnection';
import { SECRET_FIELDS } from 'constants/formFields';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNewConnectionsData = (sameEnvConfiguration: boolean | undefined, extensions: any, step: number, data: any) => {
    const extensionsObject = sameEnvConfiguration ? 0 : step;

    return extensions.map((item: IEnvironmentExtensions, index: number) => {
        if (index !== extensionsObject) {
            return item;
        }

        return {
            ...item,
            connections: {
                ...item.connections,
                connection: item.connections.connection.map((el: IConnection, i: number) => {
                    return {
                        ...el,
                        field: el.field.map((field: IConfigureField) => {
                            if (data[i]?.[field.id] === '') {
                                return {
                                    ...field,
                                    useDefault: true,
                                    value: '',
                                };
                            }

                            if (!data[i]?.[field.id]) {
                                return {
                                    ...field,
                                    useDefault: true,
                                };
                            }

                            if (SECRET_FIELDS.includes(field.id)) {
                                return {
                                    ...field,
                                    value: data[i]?.[field.id],
                                    encryptedValueSet: true,
                                    usesEncryption: true,
                                    useDefault: false,
                                };
                            }

                            return {
                                ...field,
                                value: data[i]?.[field.id],
                                useDefault: false,
                            };
                        }),
                    };
                }),
            },
        };
    });
};
