import React, { FC, Fragment } from 'react';
import Dropdown from 'components/common/DropdownField/Dropdown';
import DaysBlock from 'pages/Schedule/DaysBlock';
import useScheduleBlock from 'hooks/useScheduleBlock';
import { useSelector } from 'react-redux';
import { IStore } from 'interfaces/IStore';
import { IScheduleBlock } from 'interfaces/ISchedules';
import { DAYS_DATA } from 'constants/schedule';
import { isAdvancedSchedule } from 'utils/scheduleblock';

import styles from 'pages/Schedule/ScheduleBlock.module.scss';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton, ExIcon, ExInput, IconVariant } from '@boomi/exosphere';

const ScheduleBlock: FC<IScheduleBlock> = ({
    index,
    scheduleBlocks,
    setScheduleBlocks,
    setCount,
    scheduleBlocksToSend,
    setScheduleBlocksToSend,
    alreadyScheduledBlocksToSend,
    setAlreadyScheduledBlocksToSend,
}) => {
    const scheduleBlockObj = {
        index: index,
        scheduleBlocks: scheduleBlocks,
    };

    const {
        t,
        selectedItem,
        inputValue,
        displayError,
        selectedHours,
        checkAllDays,
        deleteScheduleBlock,
        handleOnBlur,
        handleInputChange,
        handleHourTo,
        handleHourFrom,
        handleIntervalDropdownChange,
        SCHEDULE_INTERVAL_OPTIONS,
        SCHEDULE_HOURS_OPTIONS,
        min,
        max
    } = useScheduleBlock(scheduleBlockObj, setScheduleBlocks, setCount, setScheduleBlocksToSend, scheduleBlocksToSend, alreadyScheduledBlocksToSend, setAlreadyScheduledBlocksToSend);

    const {
        runEvery,
        runFrom,
        scheduleBlock_subtitle,
        scheduleBlock_description,
        scheduleBlock_advancedSubtitle,
        scheduleBlock_advancedDescription,
        scheduleBlock_advancedSchedule,
        deleteWrapper,
        errorMessage,
        dropDownLabel,
        disabledDropdownWrapper,
        smallDropdown,
        dropdownWidth,
    } = styles;

    const { schedule: alreadyScheduledBlocks } = useSelector((state: IStore) => state.general.processes.selectedProcess.processSchedules);

    let advancedScheduleDisplayString = '';
    if (alreadyScheduledBlocks[index] !== undefined) {
        advancedScheduleDisplayString = `"minutes" : "${alreadyScheduledBlocks[index].minutes}"\n"hours" : "${alreadyScheduledBlocks[index].hours}"\n"daysOfWeek" : "${alreadyScheduledBlocks[index].daysOfWeek}"\n"daysOfMonth" : "${alreadyScheduledBlocks[index].daysOfMonth}"\n"months" : "${alreadyScheduledBlocks[index].months}"\n"years" : "${alreadyScheduledBlocks[index].years}"`;
    }

    const displayErrorType = () => {
        switch (selectedItem.value) {
            case 'Minutes':
                return <p className={errorMessage}>{t('scheduleBlock.error.minutes')}</p>;
            case 'Hour':
                return <p className={errorMessage}>{t('scheduleBlock.error.hour')}</p>;
            case 'Day':
                return <p className={errorMessage}>{t('scheduleBlock.error.days')}</p>;
            default:
                return null;
        }
    };

    const renderScheduleDescription = () => (
        displayError ? displayErrorType() : <p className={scheduleBlock_description}>{t(`${selectedItem.description}`)}</p>
    );
    return (
        <div>
            {alreadyScheduledBlocks[index] === undefined || !isAdvancedSchedule(alreadyScheduledBlocks[index]) ? (
                <Fragment>
                    <div className={dropdownWidth}>
                        <p className={scheduleBlock_subtitle}>{t('scheduleBlock.scheduleType')}</p>
                        <Dropdown options={SCHEDULE_INTERVAL_OPTIONS} handleChange={handleIntervalDropdownChange} selectedItem={selectedItem.value} />
                    </div>
                    <p className={scheduleBlock_subtitle}>{t('scheduleBlock.interval')}</p>
                    <div className={runEvery}>
                        <ExInput
                            type="number"
                            min={min}
                            max={max}
                            value={inputValue?.toString() || '1'}
                            onBlur={handleOnBlur}
                            onChange={(e: any) => {
                                const target = e.target as HTMLInputElement;
                                if (target.value === '' || parseInt(target.value, 10) < 1) {
                                    target.value = '1';
                                }
                                handleInputChange(e);
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown' && e.key !== 'Backspace' && e.key !== 'Delete') {
                                    e.preventDefault();
                                }
                            }}
                            data-testid="ex-input"
                        />
                    </div>
                    {renderScheduleDescription()}
                    <p className={scheduleBlock_subtitle}>{t('scheduleBlock.runFrom')}</p>
                    <div className={runFrom}>
                        <div>
                            <p className={dropDownLabel}>{t('scheduleBlock.hr')}</p>
                            <Dropdown options={SCHEDULE_HOURS_OPTIONS} handleChange={handleHourFrom} selectedItem={selectedHours[0].value} className={smallDropdown} />
                        </div>
                        <div>
                            <p className={dropDownLabel}>{t('scheduleBlock.min')}</p>
                            <div className={disabledDropdownWrapper}>
                                <Dropdown options={[{ value: '00', label: '00' }]} handleChange={handleHourTo} selectedItem={'00'} />
                            </div>
                        </div>
                    </div>
                    <p className={scheduleBlock_subtitle}>{t('scheduleBlock.runUntil')}</p>
                    <div className={runFrom}>
                        <div>
                            <p className={dropDownLabel}>{t('scheduleBlock.hr')}</p>
                            <Dropdown options={SCHEDULE_HOURS_OPTIONS} handleChange={handleHourTo} selectedItem={selectedHours[1].value} />
                        </div>
                        <div>
                            <p className={dropDownLabel}>{t('scheduleBlock.min')}</p>
                            <div className={disabledDropdownWrapper}>
                                <Dropdown options={[{ value: '59', label: '59' }]} handleChange={handleHourTo} selectedItem={'59'} />
                            </div>
                        </div>
                    </div>

                    <DaysBlock
                        days={DAYS_DATA}
                        numOfCheckedDays={5}
                        {...{
                            index,
                            checkAllDays,
                            scheduleBlocksToSend,
                            setScheduleBlocksToSend,
                            alreadyScheduledBlocksToSend,
                            setAlreadyScheduledBlocksToSend,
                        }}
                    />
                </Fragment>
            ) : (
                <>
                    <h3 className={scheduleBlock_advancedSubtitle}>{t('scheduleBlock.advancedSchedule.subtitle')}</h3>
                    <p className={scheduleBlock_advancedDescription}>{t('scheduleBlock.advancedSchedule.description')}</p>
                    <pre className={scheduleBlock_advancedSchedule}>{advancedScheduleDisplayString}</pre>
                </>
            )}
            <div className={deleteWrapper}>
                <ExButton onClick={deleteScheduleBlock} type={ButtonType.SECONDARY} flavor={ButtonFlavor.RISKY} size={ButtonSize.DEFAULT}>
                    <ExIcon hideBrowserTooltip={true} variant={IconVariant.DANGER} icon="delete" slot="prefix"></ExIcon> {t('scheduleBlock.delete')}
                </ExButton>
            </div>
        </div>
    );
};

export default ScheduleBlock;
