import { ICardItem } from 'interfaces/ICardContainer';
import { IConnectionsArr, IRowData } from 'interfaces/IConfigureConnection';
import { IExtensionsStatus } from 'interfaces/IExtensionsData';
import { IDataMapsTable, ISourceField } from 'interfaces/IGenericTable';
import { IProcessRunStatus, ISingularSchedule } from 'interfaces/IProcesses';
import { IEnvironmentItem, IInstalledData, IInstalledEnv } from 'interfaces/IInstalled';
import {
    SET_IS_SEARCHING,
    SET_SEARCH_RESULTS,
    SET_IS_LOADING,
    SET_SELECTED_IPACK,
    SET_SELECTED_ENVIRONMENTS,
    SET_MODAL_STATUS,
    SET_DROPDOWN_OPTION,
    SET_SEARCH_QUERY,
    SET_SAME_ENV_CONFIGURATION,
    SET_DIFFERENT_ENV_STEP,
    SET_NETWORK_ERROR,
    SET_MODAL_TYPE,
    SET_SELECTED_SOURCE_FIELD,
    SET_FILTERED_TABLE,
    SET_DATA_MAPS,
    SET_SELECTED_PROCESS,
    SET_EXTENSIONS_STATUS,
    SET_RADIO_GROUP,
    SET_EXTENSIONS,
    SET_CURRENT_PAGE,
    UPDATE_EXTENSIONS,
    SET_PROCESS_RUN_STATUS,
    SET_PROCESS_SCHEDULES,
    SET_INSTALLED_DATA,
    SET_IS_RUNS_EVERY_ERROR,
    SET_IS_DAYS_BLOCK_ERROR,
    SET_ACCOUNT_LIST,
    SET_IS_EDIT_INSTALL,
    RESET_GENERAL_STATE,
    SET_EXTENSION_PROCESSES,
    SET_SELECTED_EXTENSION_PROCESS,
    SET_INSTANCE_PROCESSES,
    SET_SELECTED_DATA_MAP,
    SET_IS_IPACK_EDIT,
    SET_IS_EDIT_UNINSTAL_IPACK_INSTANCE,
    SET_IS_EDIT_UNINSTALL_FROM_LAST_ENV,
    SET_BROWSE_SWITCH_STATUS,
    SET_RUN_NOW_CONFIRMATION_MODAL_STATUS,
    SET_FAILED_MODAL_TYPE,
    SET_AUTHORIZATION_PARAMETER_VALUE,
    SET_ACCESS_TOKEN_PARAMETER_VALUE,
    SET_TOKEN_GENERATED_STATUS
} from './actionTypes';
import { IAccounts } from 'interfaces/IAccounts';
import { IKeyStringValueString } from 'interfaces/IGeneral';

export const setIsSearching = (payload: boolean) => {
    return {
        type: SET_IS_SEARCHING,
        payload,
    };
};

export const setSearchResults: (value: IEnvironmentItem[]) => { type: unknown; payload: IEnvironmentItem[] } = (payload) => {
    return {
        type: SET_SEARCH_RESULTS,
        payload,
    };
};

export const setIsEditUninstallIpackInstance = (payload: boolean) => {
    return {
        type: SET_IS_EDIT_UNINSTAL_IPACK_INSTANCE,
        payload,
    };
};

export const setIsEditUninstallFromLastEnv = (payload: boolean) => {
    return {
        type: SET_IS_EDIT_UNINSTALL_FROM_LAST_ENV,
        payload,
    };
};

export const setSearchQuery = (payload: string) => {
    return {
        type: SET_SEARCH_QUERY,
        payload,
    };
};

export const setDropdownOption = (payload: string) => {
    return {
        type: SET_DROPDOWN_OPTION,
        payload,
    };
};

export const setIsLoading = (payload: boolean) => {
    return {
        type: SET_IS_LOADING,
        payload,
    };
};

export const setSelectedIPack = (payload: ICardItem) => {
    return {
        type: SET_SELECTED_IPACK,
        payload,
    };
};

export const setSelectedEnvironments = (payload: IInstalledEnv[]) => {
    return {
        type: SET_SELECTED_ENVIRONMENTS,
        payload,
    };
};

export const setModalStatus = (payload: boolean) => {
    return {
        type: SET_MODAL_STATUS,
        payload,
    };
};

export const setRunNowConfirmationModalStatus = (payload: boolean) => {
    return {
    type: SET_RUN_NOW_CONFIRMATION_MODAL_STATUS,
        payload,
    };
};

export const setAuthorizationParameterValue = (payload: IRowData[]) => {
    return {
        type: SET_AUTHORIZATION_PARAMETER_VALUE,
        payload,
    };
};

export const setAccessTokenParameterValue = (payload: IRowData[]) => {
    return {
        type: SET_ACCESS_TOKEN_PARAMETER_VALUE,
        payload,
    };
};

export const setSameEnvConfiguration = (payload: boolean) => {
    return {
        type: SET_SAME_ENV_CONFIGURATION,
        payload,
    };
};

export const setDifferentEnvStep = (payload: number) => {
    return {
        type: SET_DIFFERENT_ENV_STEP,
        payload,
    };
};

export const setNetworkError = (payload: boolean) => {
    return {
        type: SET_NETWORK_ERROR,
        payload,
    };
};

export const setModalType = (payload: any) => {
    return {
        type: SET_MODAL_TYPE,
        payload,
    };
};

export const setAccessTokenFailedModalType = (payload: any) => {
    return {
        type: SET_FAILED_MODAL_TYPE,
        payload,
    };
};

export const setSelectedSourceField = (payload: ISourceField) => {
    return {
        type: SET_SELECTED_SOURCE_FIELD,
        payload,
    };
};

export const setFilteredTable = (payload: []) => {
    return {
        type: SET_FILTERED_TABLE,
        payload,
    };
};

export const setDataMaps = (payload: IDataMapsTable[]) => {
    return {
        type: SET_DATA_MAPS,
        payload,
    };
};

export const setSelectedProcess = (payload: unknown) => {
    return {
        type: SET_SELECTED_PROCESS,
        payload,
    };
};

export const setExtensionsStatus = (payload: IExtensionsStatus) => {
    return {
        type: SET_EXTENSIONS_STATUS,
        payload,
    };
};

export const setRadioGroup = (payload: boolean) => {
    return {
        type: SET_RADIO_GROUP,
        payload,
    };
};

export const setExtensions = (payload: IConnectionsArr['result']) => {
    return {
        type: SET_EXTENSIONS,
        payload,
    };
};

export const updateExtensions = (payload: IConnectionsArr['result']) => {
    return {
        type: UPDATE_EXTENSIONS,
        payload,
    };
};

export const setCurrentPage = (payload: number) => {
    return {
        type: SET_CURRENT_PAGE,
        payload,
    };
};

export const setProcessRunStatus = (payload: IProcessRunStatus) => {
    return {
        type: SET_PROCESS_RUN_STATUS,
        payload,
    };
};

export const setProcessSchedules = (payload: ISingularSchedule[]) => {
    return {
        type: SET_PROCESS_SCHEDULES,
        payload,
    };
};

export const setIsRunsEveryError = (payload: boolean) => {
    return {
        type: SET_IS_RUNS_EVERY_ERROR,
        payload,
    };
};

export const setIsDaysBlockError = (payload: boolean) => {
    return {
        type: SET_IS_DAYS_BLOCK_ERROR,
        payload,
    };
};

export const setInstalledData = (payload: IInstalledData['data']) => {
    return {
        type: SET_INSTALLED_DATA,
        payload,
    };
};

export const setAccountList = (payload: IAccounts[]) => {
    return {
        type: SET_ACCOUNT_LIST,
        payload,
    };
};

export const setExtensionProcesses = (payload: IKeyStringValueString[]) => {
    return {
        type: SET_EXTENSION_PROCESSES,
        payload,
    };
};

export const setSeletedExtensionProcess = (payload: string) => {
    return {
        type: SET_SELECTED_EXTENSION_PROCESS,
        payload,
    };
};

export const setInstanceProcesses = (payload: IKeyStringValueString[]) => {
    return {
        type: SET_INSTANCE_PROCESSES,
        payload,
    };
};

export const setSelectedDataMap = (payload: string) => {
    return {
        type: SET_SELECTED_DATA_MAP,
        payload,
    };
};

export const setIsEditInstall = (payload: boolean) => {
    return {
        type: SET_IS_EDIT_INSTALL,
        payload,
    };
};

export const setIsIpackEdit = (payload: boolean) => {
    return {
        type: SET_IS_IPACK_EDIT,
        payload,
    };
};

export const setArtifactState = (payload: string) => {
    return {
        type: SET_BROWSE_SWITCH_STATUS,
        payload,
    };
};

export const resetState = (payload: any) => {
    return {
        type: RESET_GENERAL_STATE,
        payload,
    };
};

export const setTokenGeneratedStatus = (payload: any) => {
    return {
        type: SET_TOKEN_GENERATED_STATUS,
        payload,
    };
};
