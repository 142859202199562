import { NUMBER_OF_EXECUTION_HISTORY } from '../constants/api'
import { IOAuthConnectorConfig } from '../interfaces/IOAuthConnectorConfig';

export const getDynamicExtensionsQuery = (id: string, envIds: string[]) => {
    return {
        query: `
{
  integrationPackEnvironmentAttachments(ipackInstanceId:${JSON.stringify(id)}, environmentIds:${JSON.stringify(envIds)}){
    environmentExtensions
    environmentMapExtension
  }
}
`,
    };
};

export const getDynamicConnectionQuery = (id: string, envIds: string[]) => {
    return {
        query: `
{
  integrationPackEnvironmentAttachments(ipackInstanceId:${JSON.stringify(id)}, environmentIds:${JSON.stringify(envIds)}){
    environmentExtensions
  }
}
`,
    };
};

export const getDynamicPropertiesQuery = (id: string, envIds: string[]) => {
    return {
        query: `
{
  integrationPackEnvironmentAttachments(ipackInstanceId:${JSON.stringify(id)}, environmentIds:${JSON.stringify(envIds)}){
  environmentExtensions
}
}
`,
    };
};

export const getDynamicMappingsQuery = (id: string, envIds: string[]) => {
    return {
        query: `
{
  integrationPackEnvironmentAttachments(ipackInstanceId:${JSON.stringify(id)}, environmentIds:${JSON.stringify(envIds)}){
    environmentMapExtension
  }
}
`,
    };
};

export const getInstallID = (name: string, id: string) => {
    return {
        query: `
        mutation installIpack{
          integrationPackInstanceInstall(input:{
              integrationPackOverrideName: ${JSON.stringify(name)}
              integrationPackId: ${JSON.stringify(id)}
          }){
              id
              integrationPackId
              integrationPackOverrideName
      
          }
      }
      `,
    };
};

export const getInstallEnv = (id: string, envArr: string[]) => {
    return {
        envQuery: `
        mutation attachIpackInstance {
          integrationPackInstanceEnvironmentAttachmentAttach(input:{
                    integrationPackInstanceId: ${JSON.stringify(id)},
                    environmentIds: ${JSON.stringify(envArr).replace(/'/g, '"')},
          }){
            id
            environmentId
            integrationPackInstanceId
            environmentExtensions
          }
        }
        `,
    };
};

export const getUninstall = (id: string) => {
    return {
        uninstallQuery: `
        mutation unInstallIpack{
          integrationPackInstanceUninstall(id: ${JSON.stringify(id)})
        }
        `,
    };
};

export const getUnattach = (id: string) => {
    return {
        unAttachQuery: `
        mutation unattachIpackInstance {
          integrationPackInstanceEnvironmentAttachmentUnattach(id: ${JSON.stringify(id)})
        }
        `,
    };
};

export const updateExtensions = (extensionsArr: string) => {
    return {
        updateExtensionQuery: `
        mutation updateExtension{
          extensionsUpdate(input :[${extensionsArr}])
      }
        `,
    };
};

export const updateMappers = (extensionsArr: string) => {
    return {
        updateMappersQuery: `
        mutation extendedMappersUpdate{
            extendedMappersUpdate(input :[${extensionsArr}])
      }
        `,
    };
};

export const getDynamicProcessesListQuery = (integrationPackInstanceId: string) => {
    return {
        query: `
    query ipackProcesses{
      integrationPackInstanceProcesses(integrationPackInstanceId: ${JSON.stringify(integrationPackInstanceId)}){
      id
      name
      integrationPack{
          integrationPackId
          integrationPackInstanceId
      }
      processSchedules{
        id
        processId
        atomId
        envId
        schedule{
                minutes
              hours
              daysOfWeek
              daysOfMonth
              months
              years
          }
        retry{
                    schedule{
              minutes
              hours
              daysOfWeek
              daysOfMonth
              months
              years
          }
          maxRetry
        }
    }
  }
  }
    `,
    };
};

export const getScheduleMutationQuery = (mutationObj: string, processSchedulesId: string) => {
    return {
        query: `
    mutation updateSchedules{
      processSchedulesUpdate(processSchedules: ${mutationObj}, processSchedulesId: ${JSON.stringify(processSchedulesId)})
      {
          atomId
          processId
          schedule{
              minutes
              hours
              daysOfWeek
              daysOfMonth
              months
              years
          }
          retry{
              schedule{
                  minutes
                  hours
                  daysOfWeek
                  daysOfMonth
                  months
                  years
              }
              maxRetry
          }
      }
  }
    `,
    };
};

export const getExecuteProcessQuery = (atomId: string, processId: string) => {
    return {
        runProcessQuery: `
            mutation executeProcess{
                processExecute(input:{
                atomId: ${JSON.stringify(atomId)}
                processId: ${JSON.stringify(processId)}            
                })          
            }
        `,
    };
};

export const getSpace = (accountId: string) => {
    return {
        getSpaceQuery: `
        mutation space{
            spaceCreate(input:{
              name: "test"
              description: "test"
              sharingMode: ACCOUNT_GROUP
              accountGroupId: ${JSON.stringify(accountId)}
              integrationPacks: []
              accountIds: []
            }){
                id
              }
          }
        `,
    };
};

export const deleteSpace = (spaceId: string) => {
    return {
        deleteSpaceQuery: `
        mutation space{
            spaceDelete(id: ${JSON.stringify(spaceId)})
          }
        `,
    };
};

export const getExecutionHistoryQuery = (startDate: string, status: string) => {
    let statusString = '';

    if (status) {
        statusString = `status: ${status},`;
    }
    return {
        query: `
            query executionHistory {
                executionHistory(input:{
                    executionTime: "${startDate}",
                    ${statusString},
                    numberOfResults:${NUMBER_OF_EXECUTION_HISTORY}
                }) {
                    executionId
                    executionTime
                    status
                    executionType
                    processName
                    inboundDocumentCount
                    inboundErrorDocumentCount
                    outboundDocumentCount
                    executionDuration
                    message
                    inboundDocumentSize
                    outboundDocumentSize
                }
            }
        `,
    };
};

export const setOAuthConnectorDetails = (id: string | null, data: IOAuthConnectorConfig) => {
    return {
        oAuthConnectorDetailsMutation: `
        mutation addIPackInstanceOAuthConnectorDetails{
          saveIPackInstanceOauthConnectorDetails(input:{
                    integrationPackInstanceId: ${JSON.stringify(id)},
                    baseURL: ${JSON.stringify(data.baseUrl)},
                    companyId: ${JSON.stringify(data.companyId)},
                    clientId: ${JSON.stringify(data.clientId)},
                    clientSecret: ${JSON.stringify(data.clientSecret)},
                    isTokenGenerated: ${JSON.stringify(data.isTokenGenerated)},
                    oauthConnectorDetails: ${JSON.stringify(JSON.stringify(data.authParameters))},
                    authParams: ${JSON.stringify(JSON.stringify(data.authParameters))},
                    accessTokenParams: ${JSON.stringify(JSON.stringify(data.accessTokenParams))}
          }){
            id
            baseURL
            companyId
            clientId
            clientSecret
            isTokenGenerated
            authParams
            oauthConnectorDetails
            accessTokenParams
          }
        }
        `,
    };
};

export const getOAuthConnectorDetailsQuery = (id: string) => {
    return {
        oAuthConnectorDetailsQuery: `
            query getIPackOAuthConnectorDetails {
                IPackInstanceOAuthConnectorDetails(
                    ipackInstanceId: ${JSON.stringify(id)}
            ){
                id
                baseURL
                companyId
                clientId
                clientSecret
                isTokenGenerated
                oauthConnectorDetails
                authParams
                accessTokenParams
            }
        }
    `,
    };
};
