import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IConnection, IExtendedConfigureConnectionData } from 'interfaces/IConfigureConnection';
import useModalStatusScroll from 'hooks/useModalStatusScroll';
import { setOAuthConnectorDetails } from 'services/getDynamicQuery';
import { IStore } from 'interfaces/IStore';
import { reorderFields } from 'utils/reorderConnectionFields';
import { setTokenGeneratedStatus } from '../store/actions/generalActions';

const useExtendedConfigureConnection = ({
    connection,
    setConnection,
    oauthConnectorDetails,
    modalStatus,
    exists,
    id,
    makeApiCall,
    environmentAttachments,
    processEnvironmentAttachments,
    process,
    getConnectionData,
}: IExtendedConfigureConnectionData) => {
    const dispatch = useDispatch();

    useModalStatusScroll(modalStatus);

    const { authorizationParameterValue = [], accessTokenParameterValue = [], oauthTokenGeneratedStatus } = useSelector((state: IStore) => state.general || {});

    const addValuesToConnection = (connections: IConnection[], data: { [key: string]: any }): IConnection[] => {
        const keyMap: { [key: string]: string } = {
            baseUrl: 'baseURL',
            companyId: 'companyId',
            'oauthOptions/OAuth2Config/credentials/@clientId': 'clientId',
            'oauthOptions/OAuth2Config/authorizationParameters': 'authParams',
            'oauthOptions/OAuth2Config/accessTokenParameters': 'accessTokenParams',
            'oauthOptions/OAuth2Config/credentials/@accessToken': 'isTokenGenerated',
        };

        return connections.map((connection) => ({
            ...connection,
            field: connection.field.map((field: any) => {
                const mappedKey = keyMap[field.id];
                return mappedKey ? { ...field, value: data[mappedKey] } : field;
            }),
        }));
    };

    const saveOAuthConnectorDetails = async (data: any) => {
        const authorizationParamData = authorizationParameterValue.reduce((obj: any, item: any) => {
            obj[item.Key] = item.Value;
            return obj;
        }, {});
        const accessTokenParamData = accessTokenParameterValue.reduce((obj: any, item: any) => {
            obj[item.Key] = item.Value;
            return obj;
        }, {});

        const catalogsvcPayload = { ...data };
        catalogsvcPayload.baseUrl = data[0]['baseUrl'];
        catalogsvcPayload.companyId = data[0]['companyId'];
        catalogsvcPayload.clientId = data[0]['oauthOptions/OAuth2Config/credentials/@clientId'];
        catalogsvcPayload.clientSecret = 'dummyValue';
        catalogsvcPayload.authParameters = authorizationParamData;
        catalogsvcPayload.accessTokenParams = accessTokenParamData;
        catalogsvcPayload.oauthConnectorDetails = {};
        catalogsvcPayload.isTokenGenerated = oauthTokenGeneratedStatus ? oauthTokenGeneratedStatus : false;

        const { oAuthConnectorDetailsMutation } = setOAuthConnectorDetails(id, catalogsvcPayload);
        await makeApiCall(oAuthConnectorDetailsMutation, true);
    };

    useEffect(() => {
        environmentAttachments && processEnvironmentAttachments(environmentAttachments);
    }, [environmentAttachments]);

    useEffect(() => {
        if (process.length) {
            const connectionData: IConnection[] = getConnectionData(process);
            connectionData && setConnection(reorderFields([...connectionData]));
        }
    }, [process]);

    useEffect(() => {
        if (exists && oauthConnectorDetails && oauthConnectorDetails.IPackInstanceOAuthConnectorDetails) {
            const updatedConnections = addValuesToConnection(connection, oauthConnectorDetails.IPackInstanceOAuthConnectorDetails);
            const isDifferent = !updatedConnections.every((conn, index) => JSON.stringify(conn) === JSON.stringify(connection[index]));
            isDifferent && setConnection(updatedConnections);
        }
    }, [connection, oauthConnectorDetails]);

    useEffect(() => {
        if(exists && oauthConnectorDetails && oauthConnectorDetails.IPackInstanceOAuthConnectorDetails) {
            dispatch(setTokenGeneratedStatus(oauthConnectorDetails.IPackInstanceOAuthConnectorDetails.isTokenGenerated));
        } else {
            dispatch(setTokenGeneratedStatus(false));
        }

    }, [oauthConnectorDetails]);

    return { addValuesToConnection, saveOAuthConnectorDetails };
};

export default useExtendedConfigureConnection;
