import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultInputField from 'components/Form/DefaultInputField/DefaultInputField';
import { IConfigureConnectionForm } from 'interfaces/IForm';
import useConfigureConnectionForm from 'hooks/useConfigureConnectionForm';

import { ButtonFlavor, ButtonType, ExButton } from '@boomi/exosphere';
import styles from './ConfigureConnectionForm.module.scss';
import ConfigureConnectionTable from './ConfigureConnectionTable';

export const ConfigureConnectionForm: FC<IConfigureConnectionForm> = ({ setValue, register, errors, defaultValue, field, validationOptions, index, watch, connectionId, value }) => {
    const { t } = useTranslation();
    const { encryption, handleEncryption, HideInputValue, onGenerateToken, generateButton, setGenerateButton, handleEncryptionError, clientSecretHelpText } = useConfigureConnectionForm();

    const { fieldBlock, fieldBlock_buttonGroup, fieldBlock_encryptField, fieldBlock_buttonEncrypted, fieldBlock_encryptInputField, fieldBlock_encryptMessage, fieldBlock_encryptErrorMessage } = styles;
    //file contains type of input and uses the useConfigureConnectionForm hook to add functionality

    useEffect(() => {
        if (value && generateButton !== t('btn.regenerate')) {
            setGenerateButton(t('btn.regenerate'));
        }
    }, [value, generateButton]);

    const renderAccessToken = () => {
        let isDisableButton = true;
        if (watch('shouldDisableGenerateButton') === false) {
            isDisableButton = watch('shouldDisableGenerateButton');
        }
        return (
            <>
                <div className={fieldBlock}>
                    <label>{t(field.label)}</label>
                    <ExButton
                        data-testid="generate-btn-launch"
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.SECONDARY}
                        onClick={() => onGenerateToken(watch(), connectionId)}
                        className={fieldBlock_buttonGroup}
                        disabled={isDisableButton}
                    >
                        {generateButton}
                    </ExButton>
                </div>
            </>
        );
    };

    const renderClientSecret = () => {
        const clientSecretField = HideInputValue(field);
        return (
            <div>
                <div className={fieldBlock_encryptField}>
                    <div className={fieldBlock_encryptInputField}>
                        <DefaultInputField
                            field={clientSecretField}
                            defaultValue={defaultValue}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            validationOptions={validationOptions}
                            index={index}
                        />
                        {handleEncryptionError && handleEncryptionError !== 'show_generate_button' ? (
                            <pre className={fieldBlock_encryptErrorMessage}>{t('form.encryptErrorField', { handleEncryptionError })}</pre>
                        ) : (
                            <pre className={fieldBlock_encryptMessage}>{clientSecretHelpText}</pre>
                        )}
                    </div>
                    <div>
                        <ExButton
                            data-testid="Click-to-set-btn-launch"
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.SECONDARY}
                            onClick={() => handleEncryption(watch(), setValue, field)}
                            className={fieldBlock_buttonEncrypted}
                        >
                            {encryption}
                        </ExButton>
                    </div>
                </div>
            </div>
        );
    };

    const renderParameters = () => (
        <>
            <label>{t(field.label)}</label>
            <ConfigureConnectionTable field={field.label} value={value} />
        </>
    );
    const renderDefaultField = () => (
        <DefaultInputField field={field} defaultValue={defaultValue} register={register} errors={errors} setValue={setValue} validationOptions={validationOptions} index={index} />
    );

    const renderField = () => {
        switch (field.label) {
            case 'Access Token':
                return renderAccessToken();
            case 'Client Secret':
                return renderClientSecret();
            case 'Authorization Parameters':
            case 'Access Token Parameters':
                return renderParameters();
            default:
                return renderDefaultField();
        }
    };

    return <div>{renderField()}</div>;
};

export default ConfigureConnectionForm;
