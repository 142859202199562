import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IStore } from 'interfaces/IStore';

import { IProcessSchedule } from 'interfaces/IProcesses';
import { updateScheduleBlockToSend } from 'utils/updateScheduleBlockToSend';
import { setIsDaysBlockError } from 'store/actions/generalActions';
import useCheckedDays from './useCheckedDays';

const useDaysBlock = (
    checkedDay: { days: { value: string }[]; numOfCheckedDays: number; index: number; checkAllDays: boolean },
    scheduleBlocksToSend: IProcessSchedule['schedule'],
    setScheduleBlocksToSend: React.Dispatch<React.SetStateAction<IProcessSchedule['schedule']>>,
    alreadyScheduledBlocksToSend: IProcessSchedule['schedule'],
    setAlreadyScheduledBlocksToSend: React.Dispatch<React.SetStateAction<IProcessSchedule['schedule']>>,
) => {
    const { days, numOfCheckedDays, index, checkAllDays } = checkedDay;

    const alreadyScheduledBlocks = useSelector((state: IStore) => state.general.processes.selectedProcess.processSchedules.schedule);
    const [checkboxError, setCheckboxError] = useState(false);
    const { getCheckedDaysArray, getDaysOfWeek, getFilteredDaysOfWeek, initialState, setChkboxError } = useCheckedDays(days, numOfCheckedDays, setCheckboxError);
    const [checkedDays, setCheckedDays] = useState(alreadyScheduledBlocks[index]? getCheckedDaysArray(alreadyScheduledBlocks[index].daysOfWeek) : initialState);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleInputChange = (evt: any, i: number) => {
        const modifiedCheckedDays = [...checkedDays];
        modifiedCheckedDays.splice(i, 1, evt.target.checked);
        setCheckedDays(modifiedCheckedDays);
    };
    const updateConditionCheck =(filteredDaysOfWeek:any) =>{
        if (scheduleBlocksToSend[index - alreadyScheduledBlocks.length] === undefined) {
            const scheduleData = scheduleBlocksToSend.filter((item) => item.index === index);
            const [firstScheduleData] = scheduleData;
            const objToModify = { ...firstScheduleData, daysOfWeek: filteredDaysOfWeek.toString() };
            updateScheduleBlockToSend(objToModify, scheduleBlocksToSend, setScheduleBlocksToSend, alreadyScheduledBlocks, index);
        } else {
            const objToModify = { ...scheduleBlocksToSend[index - alreadyScheduledBlocks.length], daysOfWeek: filteredDaysOfWeek.toString() };
            updateScheduleBlockToSend(objToModify, scheduleBlocksToSend, setScheduleBlocksToSend, alreadyScheduledBlocks, index);
        }
    }

    const updateScheduleBlock = (checkedDays: boolean[]) => {
        const daysOfWeek = getDaysOfWeek(checkedDays);
        const filteredDaysOfWeek = getFilteredDaysOfWeek(daysOfWeek);

        if (index + 1 > alreadyScheduledBlocks.length) {
            updateConditionCheck(filteredDaysOfWeek);
        } else {
            const objToModifyAlreadyScheduled = {
                ...alreadyScheduledBlocks[index],
                index: index,
                daysOfWeek: filteredDaysOfWeek.toString(),
                daysOfMonth: checkAllDays? '*' : alreadyScheduledBlocks[index].daysOfMonth,
            };
            updateScheduleBlockToSend(objToModifyAlreadyScheduled, alreadyScheduledBlocksToSend, setAlreadyScheduledBlocksToSend, alreadyScheduledBlocksToSend, index);
        }
    };

    useEffect(() => {
        setCheckboxError(false);
        setChkboxError(checkedDays);
        updateScheduleBlock(checkedDays);
    }, [checkedDays]);

    useEffect(() => {
        checkAllDays && setCheckedDays(Array(days.length).fill(true));
    }, [checkAllDays]);

    useEffect(() => {
        if (alreadyScheduledBlocks[index]) {
            const { daysOfWeek } = alreadyScheduledBlocks[index];
            setCheckedDays(getCheckedDaysArray(daysOfWeek));
        }
    }, []);

    useEffect(() => {
        if (checkboxError) {
            dispatch(setIsDaysBlockError(true));
            return;
        } 
            dispatch(setIsDaysBlockError(false));
        
    }, [checkboxError]);

    return { t, checkboxError, handleInputChange, checkedDays };
};

export default useDaysBlock;
