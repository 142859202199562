import { ExIcon, ExTable, ExButton, ButtonFlavor, ButtonType } from '@boomi/exosphere';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useConfigureConnectionTable from 'hooks/useConfigureConnectionTable';
import { ITableApi } from '../../../interfaces/IGrid';
import { IConfigureConnectionTable } from 'interfaces/IForm';
import styles from './ConfigureConnectionTable.module.scss';

const ConfigureConnectionTable: FC<IConfigureConnectionTable> = ({ field , value}) => {
    const { t } = useTranslation();

    const { defaultRowData, handlePropertyAdd, handleDelete, handleRowData, accessTokenError, authorizationError } = useConfigureConnectionTable(field , value);

    const { errorMessage } = styles;

    const [gridApi, setGridApi] = useState<ITableApi>({});

    const handleOnChangeKeyParams = (e: any, param: any) => {
        handleRowData(e.target.value, param);
    };

    const handleOnChangeValueParams = (e: any, param: any) => {
        param.node.setDataValue(param.colDef.field, e.target.value);
        handleRowData(e.target.value, param);
    };

    const actionRenderer = (params: any) => {
        const wrapper = document.createElement('div');
        const deleteButton = document.createElement('span');
        deleteButton.innerHTML = t('Delete');
        deleteButton.id = `deleteButton-span-button`;
        deleteButton.style.textDecoration = 'underline';
        deleteButton.style.cursor = 'pointer';
        deleteButton.style.font = 'var(--exo-text-link-standard)';
        deleteButton.style.color = 'var(--exo-color-font-link)';
        deleteButton.addEventListener('click', () => handleDelete(params.data.id));
        wrapper.appendChild(deleteButton);
        return wrapper;
    };

    const keyRenderer = (params: any) => {
        const wrapper = document.createElement('div');
        const keyElement = document.createElement('input');
        keyElement.id = `keyElement-span-button`;
        keyElement.style.cursor = 'pointer';
        keyElement.value = params.value;
        keyElement.addEventListener('change', (e) => handleOnChangeKeyParams(e, params));
        wrapper.appendChild(keyElement);
        return wrapper;
    };

    const valueRenderer = (params: any) => {
        const wrapper = document.createElement('div');
        const valueElement = document.createElement('input');
        valueElement.id = `valueElement-span-button`;
        valueElement.style.cursor = 'pointer';
        valueElement.value = params.value;
        valueElement.addEventListener('change', (e) => handleOnChangeValueParams(e, params));
        wrapper.appendChild(valueElement);
        return wrapper;
    };

    const createColumnDefs = () => {
        return [
            {
                headerName: t('ConfigureConnectionTable.columns.key'),
                field: 'Key',
                tooltipField: t('ConfigureConnectionTable.columns.key'),
                cellRenderer: keyRenderer,
            },
            {
                headerName: t('ConfigureConnectionTable.columns.value'),
                field: 'Value',
                tooltipField: t('ConfigureConnectionTable.columns.value'),
                cellRenderer: valueRenderer,
                autoHeight: true,
            },
            {
                headerName: t('ConfigureConnectionTable.columns.action'),
                cellRenderer: actionRenderer,
                maxWidth: 150,
            },
        ];
    };

    const gridOptions: any = {
        defaultColDef: {
            sortable: false,
            resizable: false,
        },
        animateRows: true,
        suppressRowTransform: true,
        columnDefs: createColumnDefs(),
        rowData: defaultRowData.map((item) => ({ ...item })),
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        rowHeight: 48,
        suppressPaginationPanel: true,
        paginationPageSize: 2,
        onGridReady: (e: any) => {
            setGridApi(e.api);
        },
    };

    useEffect(() => {
        if (Object.keys(gridApi).length) {
            gridApi.setRowData(defaultRowData.map((item) => ({ ...item })));
        }
    }, [defaultRowData]);

    return (
        <div style={{ marginBottom: '26px' }}>
            <ExTable gridOptions={gridOptions} overflowVisible={true} key={defaultRowData.length} />
            <ExButton type={ButtonType.SECONDARY} flavor={ButtonFlavor.BRANDED} data-testid="add-property-button" style={{ width: 'auto', display: 'inline-block' }} onClick={handlePropertyAdd}>
                <ExIcon icon="Add new" slot="prefix"></ExIcon>
                {t('btn.addProperty')}
            </ExButton>
            {accessTokenError && <p className={errorMessage}>{t('ConfigureConnectionTable.error.accessTokenError')}</p>}
            {authorizationError && <p className={errorMessage}>{t('ConfigureConnectionTable.error.authorizationError')}</p>}
        </div>
    );
};

export default ConfigureConnectionTable;
