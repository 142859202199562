import { ExIcon, ExInput, ExTable, IconVariant, ExPagination } from '@boomi/exosphere';
import useConfirmationTableActions from 'hooks/useConfirmationTableActions';
import usePagination from 'hooks/usePagination';
import { ICellRendererParams, IGridReadyParams, ITableApi } from 'interfaces/IGrid';
import { IConfirmationTable } from 'interfaces/IProcessesTable';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ConfirmationTable.scss';
import { truncateString, truncateTooltipText } from 'utils/truncateString';

const ConfirmationTable: FC<IConfirmationTable> = ({ data, env }) => {
    const { t } = useTranslation();
    const { handleSchedule, handleRunNowConfirmationPrompt } = useConfirmationTableActions();
    const [gridApi, setGridApi] = useState<ITableApi>({});
    const { paginatedData, pageSize, selectedPage, handlePaginationChange, pageSizeOptions } = usePagination(data);

    const actionRenderer = (params: any) => {
        const wrapper = document.createElement('div');

        const runNow = document.createElement('span');
        runNow.id = `runNow-span-button`;
        runNow.style.textDecoration = 'underline';
        runNow.style.cursor = 'pointer';
        runNow.innerHTML = t('confirmationTable.runNow');
        runNow.style.font = 'var(--exo-text-link-standard)';
        runNow.style.color = 'var(--exo-color-font-link)';
        runNow.addEventListener('click', () => params.onRunNowClick(params));
        const scheduleElement = document.createElement('span');
        scheduleElement.id = 'scheduleElement-span-button';
        scheduleElement.style.textDecoration = 'underline';
        scheduleElement.style.cursor = 'pointer';
        scheduleElement.style.marginLeft = '1rem';
        scheduleElement.innerHTML = t('confirmationTable.schedule');
        scheduleElement.style.font = 'var(--exo-text-link-standard)';
        scheduleElement.style.color = 'var(--exo-color-font-link)';
        scheduleElement.addEventListener('click', () => params.onScheduleClick(params));
        wrapper.appendChild(runNow);
        wrapper.appendChild(scheduleElement);
        return wrapper;
    };

    const scheduleStatusRenderer = (params: ICellRendererParams) => {
        const { processSchedules } = params.data;
        if (processSchedules?.schedule.length) {
            return `<div style="display:flex;align-items: center;"><span>${t('confirmationTable.scheduled')}</span></div>`;
        }

        return `<div style="display:flex; align-items: center;"><span>${t('confirmationTable.notScheduled')}</span></div>`;
    };

    const processNamerenderer = (params: ICellRendererParams) => {
        const { name } = params.data;
        return `<ex-tooltip position="top" alignment="start" style="width:100%;z-index:9999999999;word-break:break-word;">
                 <span style="white-space: initial;word-break: break-word;">${truncateTooltipText(name)}</span>
                 <span slot="anchor">${truncateString(name)}</span>
                </ex-tooltip>`;
    };

    const createColumnDefs = () => {
        return [
            {
                headerName: t('confirmationTable.columns.name'),
                field: 'name',
                tooltipField: t('confirmationTable.columns.name'),
                cellRenderer: processNamerenderer,
            },
            {
                headerName: t('confirmationTable.columns.status'),
                field: 'mapStatus',
                tooltipField: t('confirmationTable.columns.status'),
                cellRenderer: scheduleStatusRenderer,
                autoHeight: true,
                maxWidth: 250,
            },
            {
                headerName: t('confirmationTable.columns.actions'),
                cellRenderer: actionRenderer,
                cellRendererParams: {
                    onRunNowClick: (params: ICellRendererParams) => {
                        const { name, id, integrationPack, processSchedules, env: envData, scheduled } = params.data;
                        handleRunNowConfirmationPrompt({ name, id, integrationPack, processSchedules, scheduled }, envData);
                    },
                    onScheduleClick: (params: ICellRendererParams) => {
                        const { name, id, integrationPack, processSchedules, env: envData, scheduled } = params.data;
                        handleSchedule({ name, id, integrationPack, processSchedules, scheduled }, envData);
                    },
                },
                maxWidth: 250,
            },
        ];
    };

    const gridOptions: any = {
        defaultColDef: {
            sortable: false,
            resizable: false,
        },
        animateRows: true,
        suppressRowTransform: true,
        columnDefs: createColumnDefs(),
        rowData: paginatedData.map((item: any) => ({ ...item, env })),
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        rowHeight: 48,
        suppressPaginationPanel: true,
        paginationPageSize: 2,
        onGridReady: (e: any) => {
            e.api.sizeColumnsToFit();
            setGridApi(e.api);
        },
        onGridSizeChanged: (params: IGridReadyParams) => {
            params.api.sizeColumnsToFit();
        },
        popupParent: document.querySelector(`body`),
    };

    useEffect(() => {
        if (Object.keys(gridApi).length) {
            gridApi.setRowData(paginatedData.map((item: any) => ({ ...item, env })));
        }
    }, [paginatedData]);

    return (
        <div id={env?.id} className="confirmation-table">
            <div className="confirmation-table-search-wrapper">
                <ExInput
                    placeholder={t('search.confirmationPlaceholder')}
                    type="text"
                    clearable={true}
                    data-testid="search-input"
                    onInput={(e: Event) => {
                        const inputValue = (e.target as HTMLInputElement).value;
                        gridApi.setQuickFilter(inputValue);
                    }}
                    label={t('search.confirmationTitle')}
                >
                    <ExIcon hideBrowserTooltip={true} icon="magnifying-glass" variant={IconVariant.ICON} label="save icon" slot="prefix"></ExIcon>
                </ExInput>
            </div>
            <ExTable gridOptions={gridOptions} overflowVisible={true} />
            <div className="ex-mt-standard">
                <ExPagination
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    selectedPage={selectedPage}
                    totalItems={data.length}
                    onChange={(e: CustomEvent<any>) => handlePaginationChange(e)}
                />
            </div>
        </div>
    );
};

export default ConfirmationTable;
