import React, { FC, memo, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { DROPDOWN_FILTER } from 'constants/dropdown/dropdownData';
import { useGenericTable } from 'hooks/useGenericTable';
import { IExtendedMappings, IGenericTable } from 'interfaces/IGenericTable';

import 'react-toastify/dist/ReactToastify.css';
import styles from 'components/common/GenericTable/GenericTable.module.scss';
import { ExIcon, ExInput, ExTable } from '@boomi/exosphere';
import Filter from '../Filter/Filter';
import './GenericTable.scss';
import { ICellRendererParams, IGridReadyParams, ITableApi } from 'interfaces/IGrid';

export const GenericTable: FC<IGenericTable> = ({ data }) => {
    const { tableWrapper } = styles;
    const { tableData, selectedItem, handleMapClick, handleTransformClick, t, handleDropdownAction } = useGenericTable(data);
    const [searchQuery, setSearchQuery] = useState('');
    const [gridApi, setGridApi] = useState<ITableApi>({});
    const actionRenderer = (params: any) => {
        const wrapper = document.createElement('div');

        const mapElement = document.createElement('span');
        mapElement.style.textDecoration = 'underline';
        mapElement.style.cursor = 'pointer';
        mapElement.innerHTML = t('label.mapAction');
        mapElement.addEventListener('click', () => params.onMapClick(params));
        mapElement.style.font = 'var(--exo-text-link-standard)';
        mapElement.style.color = 'var(--exo-color-font-link)';
        const transformElement = document.createElement('span');
        transformElement.style.textDecoration = 'underline';
        transformElement.style.cursor = 'pointer';
        transformElement.style.display = 'none';
        transformElement.style.marginLeft = '0.5rem';
        transformElement.innerHTML = t('label.transformData');
        transformElement.addEventListener('click', () => params.onTransformClick(params));
        wrapper.appendChild(mapElement);
        wrapper.appendChild(transformElement);
        return wrapper;
    };
    const mapStatusRenderer = (params: ICellRendererParams) => {
        const { mappedElem, mappedFunction } = params.data;
        if (!mappedElem && !mappedFunction) return `<div style="opacity:0.7">${t('label.notMapped')}</div>`;
        if (mappedElem) {
            return `<div>${mappedElem
                .map((elem: IExtendedMappings) => {
                    return mappedStatusElement(elem.name);
                })
                .join('')}</div>`;
        }
        if (mappedFunction) {
            return mappedFunction
                .map((func: string) => {
                    return `<div  style="display: block; text-transform: capitalize;">${func} applied</div>`;
                })
                .join('');
        }
    };

    const mappedStatusElement = (mapName: string | undefined) => `<div style="display: block; text-transform: capitalize;">
    ${t('label.mappedTo')}
    <span style="text-decoration: underline; cursor: pointer; pointer-events: none">
        ${mapName}
    </span>
    </div>`;

    const sourceFieldRenderer = (params: ICellRendererParams) => {
        const { name, xpath } = params.data;

        return `<ex-tooltip position="top" alignment="start" style="width:100%;z-index:9999999999;word-break:break-word;">
             <div style="white-space: initial;word-break: break-word;">
             ${name} (${xpath}) 
             </div><div slot="anchor" style="word-wrap: break-word;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;width: 100%;white-space: initial;" ><span>${name}</span>
         <span style="margin-left:0.5rem;opacity:0.7">${xpath}</span></div> </ex-tooltip>`;
    };
    const columnDefs = [
        {
            headerName: 'Source Fields',
            field: 'name',
            tooltipField: 'Source Fields',
            cellRenderer: sourceFieldRenderer,
        },
        {
            headerName: 'Map Status',
            field: 'mapStatus',
            tooltipField: 'Map Status',
            cellRenderer: mapStatusRenderer,
            autoHeight: true,
        },
        {
            headerName: 'Actions',
            cellRenderer: actionRenderer,
            cellRendererParams: {
                onMapClick: handleMapClick,
                onTransformClick: handleTransformClick,
            },
        },
    ];
    const gridOptions: any = {
        defaultColDef: {
            sortable: false,
            resizable: false,
        },
        animateRows: true,
        columnDefs,
        rowData: tableData,
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        onGridReady: (params: IGridReadyParams) => {
            params.api.sizeColumnsToFit();
            setGridApi(params.api);
        },
        onGridSizeChanged: (params: IGridReadyParams) => {
            params.api.sizeColumnsToFit();
        },
        popupParent: document.querySelector('body'),
    };

    useEffect(() => {
        if (Object.keys(gridApi).length) {
            gridApi.setGridOption("rowData", tableData);
        }
    }, [tableData, gridApi]);

    return (
        <>
            <div className="search-dropdown-wrapper">
                <ExInput
                    placeholder={t('search.byFieldName')}
                    type="text"
                    clearable={true}
                    className="search-dropdown-wrapper__input"
                    data-testid="search-input"
                    onInput={(e: Event) => {
                        const inputValue = (e.target as HTMLInputElement).value;
                        setSearchQuery(inputValue);
                        gridApi?.setQuickFilter(inputValue);
                    }}
                    label={t('search.title')}
                    value={searchQuery}
                >
                    <ExIcon hideBrowserTooltip={true} icon="magnifying-glass" label="save icon" slot="prefix"></ExIcon>
                </ExInput>
                <Filter
                    title={'dropdown.selectFilter'}
                    className="search-dropdown-wrapper__dropdown"
                    {...{
                        options: DROPDOWN_FILTER,
                        selectedItem,
                        contained: true,
                        handleChange: handleDropdownAction,
                    }}
                />
            </div>
            <div className={tableWrapper} id="map-table-wrapper">
                <ExTable gridOptions={gridOptions} overflowVisible={true}></ExTable>
            </div>
            <ToastContainer autoClose={5000} />
        </>
    );
};

export default memo(GenericTable);
