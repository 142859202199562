import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IFormField } from 'interfaces/IForm';
import axios from 'axios';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import Auth from '../utils/Auth';
import {
    setModalStatus,
    setModalType,
    setAccessTokenFailedModalType,
    setTokenGeneratedStatus,
} from '../store/actions/generalActions';
import axiosAbortInstance, { createAbortController, abortController, resetAbortController } from '../utils/axiosAbortInstance';
import createDeferredPromise from 'utils/CreateDeferredPromise';
import { IStore } from '../interfaces/IStore';
import { setOAuthConnectorDetails } from '../services/getDynamicQuery';
import useHandleApiCall from './useHandleApiCall';
import { useQueryParam } from './useQueryParam';

const useConfigureConnectionForm = () => {
    const { t } = useTranslation();
    const [encryption, setEncryption] = useState<string>(t('btn.encryptCS'));
    const dispatch = useDispatch();
    const { spaceAccountId, jwt } = useSelector((state: IStore) => state.login);
    const { isEdit } = useSelector((state: IStore) => state.general);
    const [generateButton, setGenerateButton] = useState<string>(t('btn.generate'));
    const [clientSecretHelpText, setClientSecretHelpText] = useState<string>(t('form.encryptField'));
    const [inputDisabled, setInputDisabled] = useState<boolean>(false);
    const [handleEncryptionError, setHandleEncryptionError] = useState(null);
    const { authorizationParameterValue = [], accessTokenParameterValue = [] } = useSelector((state: IStore) => state.general || {});

    const { makeApiCall } = useHandleApiCall();
    const { id } = useQueryParam();

    const handleEncryption = async (formData: any, setValue: any, field: IFormField) => {
        setHandleEncryptionError(null);
        setValue('shouldDisableGenerateButton', true);
        const now = Auth.getAuthDate();
        const awsAuth = Auth.getAWSToken(now, 'com.boomi.redsky.platform.gwt.common.EncryptPasswordAction');

        const headers = {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
        };

        const deferredPromise = createDeferredPromise();

        const payload = {
            password: formData[0]['oauthOptions/OAuth2Config/credentials/@clientSecret'],
            context: 'ATOMSPHERE',
            dateString: now,
            authString: awsAuth,
            accountId: spaceAccountId,
            clientUserAgent: window.navigator.userAgent,
        };
        if (!inputDisabled) {
            try {
                const { data } = await axios.post(`/restrpc/account/${spaceAccountId}/handler/com.boomi.platform.gwt.account.EncryptPasswordHandler`, payload, {
                    headers,
                } as AxiosAuthRefreshRequestConfig);
                setValue(field.inputName.split('0.')[1], data.resultString);
                setValue('shouldDisableGenerateButton', false);
                setInputDisabled(true);
                setEncryption(t('btn.resetCS'));
            } catch (error: any) {
                if (axios.isAxiosError(error)) {
                    deferredPromise.cancel(error.message);
                } else {
                    setHandleEncryptionError(error?.message ? error.message : t('error.encryptionError'));
                }
            }
        } else {
            if (field?.fieldValue?.[0]?.['oauthOptions/OAuth2Config/credentials/@clientSecret']) {
                setValue(field.inputName, '');
            }
            setInputDisabled(false);
            setEncryption(t('btn.encryptCS'));
        }
    };

    const HideInputValue = (field: IFormField) => {
        return {
            ...field,
            hiddenValue: true,
            disabled: inputDisabled,
        };
    };

    const onGenerateToken = async (data: any, connectionId: string) => {
        // show Starting access token modal
        dispatch(setModalStatus(true));
        dispatch(setModalType('openingAccessToken'));

        const authorizationParamData = authorizationParameterValue.reduce((obj: any, item: any) => {
            obj[item.Key] = item.Value;
            return obj;
        }, {});
        const accessTokenParamData = accessTokenParameterValue.reduce((obj: any, item: any) => {
            obj[item.Key] = item.Value;
            return obj;
        }, {});

        createAbortController();
        const now = Auth.getAuthDate();
        const awsAuth = Auth.getAWSToken(now, 'com.boomi.redsky.platform.gwt.common.OAuth2GenerateAction');
        const gwtPayload = {
            loginSession: null,
            sessionId: null,
            properties: {
                'oauthOptions/OAuth2Config/credentials/@clientSecret': data['oauthOptions/OAuth2Config/credentials/@clientSecret'],
                accessTokenKey: null,
                connectionId: connectionId,
                'oauthOptions/OAuth2Config/credentials/@accessToken': null,
                grantType: 'code',
                fieldId: 'oauthOptions',
                'oauthOptions/OAuth2Config/credentials/@clientId': data[0]['oauthOptions/OAuth2Config/credentials/@clientId'],
            },
            clientUserAgent: window.navigator.userAgent,
            dateString: now,
            authString: awsAuth,
            context: 'ATOMSPHERE',
            accountId: spaceAccountId,
            authParameters: authorizationParamData,
            accessTokenParameters: accessTokenParamData,
        };
        const catalogsvcPayload = { ...data };
        catalogsvcPayload.baseUrl = data[0]['baseUrl'];
        catalogsvcPayload.companyId = data[0]['companyId'];
        catalogsvcPayload.clientId = data[0]['oauthOptions/OAuth2Config/credentials/@clientId'];
        catalogsvcPayload.clientSecret = 'dummyValue';
        catalogsvcPayload.authParameters = authorizationParamData;
        catalogsvcPayload.accessTokenParams = accessTokenParamData;
        catalogsvcPayload.oauthConnectorDetails = {};
        await generateOAuthToken(gwtPayload, catalogsvcPayload);
    };

    const generateOAuthToken = async (gwtPayload: any, catalogsvcPayload: any) => {
        const headers = {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
        };

        try {
            const { data } = await axiosAbortInstance.post(`/restrpc/account/${spaceAccountId}/handler/com.boomi.platform.gwt.browse.OAuth2GenerateHandler`, gwtPayload, {
                headers,
                signal: abortController?.signal,
            } as AxiosAuthRefreshRequestConfig);
            return await getGWTRPCResponse(data, gwtPayload, catalogsvcPayload);
        } catch (error: any) {
            if (axios.isCancel(error)) {
                // When we abort an axios call, it basically throws an error and we do not want to show that on screen. hence not using deferredPromise
                console.log(error);
            } else {
                resetAbortController();
                dispatch(
                    setAccessTokenFailedModalType({
                        data: t('error.oauthAccessTokenError'),
                    }),
                );
                dispatch(setModalType('accessTokenFailed'));
            }
        }
    };

    const getGWTRPCResponse = async (result: any, gwtPayload: any, catalogsvcPayload: any) => {
        let rt;
        if (result.resultType) {
            rt = result.resultType;
        }
        switch (rt) {
            case 'SESSION_ID':
                gwtPayload = {
                    ...gwtPayload,
                    sessionId: result.sessionId,
                };
                await generateOAuthToken(gwtPayload, catalogsvcPayload);
                break;
            case 'RESULT':
                await onReceivingResult(result, gwtPayload, catalogsvcPayload);
                break;
            case 'MESSAGE':
                await onReceivingMessage(result, gwtPayload, catalogsvcPayload);
                break;
            default:
                return result;
        }
    };

    const onReceivingResult = async (result: any, gwtPayload: any, catalogsvcPayload: any) => {
        if (result.oauthRequestResponse?.requestURL) {
            //open a new tab and navigate user
            window.open(`${result.oauthRequestResponse.requestURL}`, '_blank');
            //3rd RPC calls to get the new session Id
            gwtPayload = {
                ...gwtPayload,
                sessionId: null,
                loginSession: gwtPayload.sessionId,
                properties: {
                    objectRequested: 'getAccessToken',
                },
            };
            await generateOAuthToken(gwtPayload, catalogsvcPayload);
        } else if (result.oauthAccessResponse?.accessTokenKey) {
            // Access token generated successfully modal
            catalogsvcPayload.isTokenGenerated = true;
            const { oAuthConnectorDetailsMutation } = setOAuthConnectorDetails(id, catalogsvcPayload);
            await makeApiCall(oAuthConnectorDetailsMutation, true);
            dispatch(setTokenGeneratedStatus(true));
            dispatch(setModalType('accessTokenSucess'));
            setGenerateButton(t('btn.regenerate'));
            return result;
        }
    };

    const onReceivingMessage = async (result: any, gwtPayload: any, catalogsvcPayload: any) => {
        if (result.message.type === 'error') {
            resetAbortController();
            dispatch(
                setAccessTokenFailedModalType({
                    data: result.message.Data,
                }),
            );
            dispatch(setModalType('accessTokenFailed'));
        } else {
            // show Initializing OAuth token modal
            dispatch(setModalType('initializingOAuthToken'));
            setTimeout(async () => {
                await generateOAuthToken(gwtPayload, catalogsvcPayload);
            }, 5000);
        }
    };

    useEffect(() => {
        if (isEdit) {
            setClientSecretHelpText(t('form.encryptRegenerateField'));
        }
    }, [isEdit]);

    return {
        encryption,
        handleEncryption,
        HideInputValue,
        onGenerateToken,
        generateButton,
        setGenerateButton,
        handleEncryptionError,
        clientSecretHelpText,
        inputDisabled,
        generateOAuthToken,
        getGWTRPCResponse,
        onReceivingResult,
        onReceivingMessage,
        setInputDisabled,
    };
};

export default useConfigureConnectionForm;
