import { IRowData } from 'interfaces/IConfigureConnection';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthorizationParameterValue, setAccessTokenParameterValue } from 'store/actions/generalActions';

const useConfigureConnectionTable = (field: string, value: any) => {
    const initialRowData: IRowData[] = [];

    const [defaultRowData, setDefaultRowData] = useState<IRowData[]>([]);
    const [index, setIndex] = useState<number>(initialRowData.length);
    const [accessTokenError, setAccessTokenError] = useState<boolean>(false);
    const [authorizationError, setAuthorizationError] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handlePropertyAdd = () => {
        setIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            const newRow: IRowData = { id: newIndex, Key: ``, Value: '' };
            setDefaultRowData((prevData) => [...prevData, newRow]);
            return newIndex;
        });
    };

    const handleDelete = (id: number) => {
        setDefaultRowData((prevData) => prevData.filter((row) => row.id !== id));
    };

    const handleRowData = (data: string, param: any) => {
        setDefaultRowData((prevData) => prevData.map((row) => (row.id === param.data.id ? { ...row, [param.colDef.field]: data } : row)));
    };

    const checkForDuplicateKeyValues = (rowData: IRowData[], field: string) => {
        // Filter out rows where the Key is an empty string
        const filteredRowData = rowData.filter((row) => row.Key !== '');

        const keyValues = filteredRowData.map((row) => row.Key);
        const uniqueKeyValues = new Set(keyValues);

        if (field === 'Authorization Parameters' && keyValues.length !== uniqueKeyValues.size) {
            setAuthorizationError(true);
            return;
        } else if (field === 'Access Token Parameters' && keyValues.length !== uniqueKeyValues.size) {
            setAccessTokenError(true);
            return;
        }

        setAccessTokenError(false);
        setAuthorizationError(false);
    };

    useEffect(() => {
        checkForDuplicateKeyValues(defaultRowData, field);
        if (field == 'Authorization Parameters') {
            dispatch(setAuthorizationParameterValue([...defaultRowData]));
            return;
        }
        dispatch(setAccessTokenParameterValue([...defaultRowData]));
    }, [defaultRowData]);

    useEffect(() => {
        if (value) {
            const parsedInputValue = JSON.parse(value);
            const rowDataArray: IRowData[] = Object.entries(parsedInputValue).map(([key, value], index) => ({
                id: index + 1,
                Key: key,
                Value: value,
            }));
            setDefaultRowData(rowDataArray);
            setIndex(rowDataArray.length);
            return;
        }
        setDefaultRowData([]);
    }, [value]);

    return { defaultRowData, handlePropertyAdd, handleDelete, handleRowData, index, accessTokenError, authorizationError };
};

export default useConfigureConnectionTable;
